import React from "react";

import { Section, Container, Title, Markdown, getProperty } from "@lachevaliniere/shared";

import { useStaticPrivacy } from "hooks";

export const Content = () => {
  const staticPrivacy = useStaticPrivacy();
  const title = getProperty(staticPrivacy, "data.metaTitle");
  const content = getProperty(staticPrivacy, "data.content");

  return (
    <Section>
      <Container>
        <Title wrapper="h1">{title}</Title>
        <Markdown content={content} />
      </Container>
    </Section>
  );
};
